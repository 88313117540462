/* ServiceProviderProfile.css */

.profile-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 85px;
}

.taskBar {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  justify-content: end;
  padding: 20px 0;
}

.settings {
  position: relative;
}

.user-name{
  color: #343434;
}

.settings button {
  background: none;
  border: none;
  font-size:small;
}
.settings img {
  vertical-align: middle;
}

.settings-menu {
  position: relative;
  top: -10px;
  right: 0;
  background-color: #970000;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
}

.settings-menu button {
  display: block;
  width: 100%;
  padding: 10px,10px;
  border: none;
  background: none;
  text-align: left;
  font-size: medium;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}

.profile-pic {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.profile-pic img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.default-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ff6b6b;
  color: white;
  font-size: 48px;
  line-height: 150px;
  text-align: center;
}

.file1 {
  display: none;
}

.edit-btn1 {
  display: block;
  margin: 10px auto;
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn1:hover {
  background-color: #ff4949;
}

.company-info {
  text-align: center;
}

.company-info h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.description-container {
  display: inline-block;
  margin-top: 10px; 
  width:fit-content;
  position: relative;
}

.editable-textarea {
  width: 100%;
  height:350px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
}


.aboutus {
  font-size: 16px;
  line-height: 1.5;
}

.edit-icon {
  width: 24px;
  cursor: pointer;
  margin-left: 10px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin: 20px 0;
}

.services-areas {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.section-block {
  width: 48%;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.section-block h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ff6b6b;
}

.section-block p {
  font-size: 16px;
  color: #333;
}

.contact-details {
  background-color: #ffecec;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.contact-details h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #ff6b6b;
}

.contact-details p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.post-section {
  margin-bottom: 20px;
  text-align: center;
}

.post-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ff6b6b;
}

.upload-btn {
  display: block;
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px auto;
}

.upload-btn:hover {
  background-color: #ff4949;
}

.upload-btn input {
  display: none;
}

.find-ads-btn {
  display: block;
  width: 100%;
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
}

.find-ads-btn:hover {
  background-color: #0088cc;
}

@media (max-width: 768px) {
  .services-areas {
    flex-direction: column;
  }

  .section-block {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-pic img, .default-logo {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 36px;
  }

  .company-info h1 {
    font-size: 20px;
  }

  .description-container {
    margin-top: 5px;
  }

  .edit-btn1, .find-ads-btn, .upload-btn {
    padding: 8px;
  }

  .contact-details {
    padding: 15px;
  }

  .contact-details h3 {
    font-size: 18px;
  }

  .post-section h3 {
    font-size: 18px;
  }

  .line {
    margin: 10px 0;
  }
}


.poster-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}

.post-images {
  position: relative;
  margin-bottom: 20px;

}

.post-images img {
  width: 350px;
  height: 487px;
  border-radius: 5px;
  /* object-fit: cover; */
  border: 2px solid #ddd;
  cursor: pointer;
}

.default-poster {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 487px;
  border-radius: 5px;  
  background-color: #ffffff;
  color: rgb(152, 152, 152);
  font-size: 26px;
  /* line-height: 30px; */
  text-align: center;
  border: 1px solid #ddd;
  
      
}

input[type="file"] {
  display: none; /* Keeps the file input hidden */
}


.post-btn1 {
  display: block;
  margin: 10px auto;
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.post-btn1:hover {
  background-color: #ff4949;
}

.verification-message {
  display: block;
  width: 50%;
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
}

.verified {
  background-color: #199909;
  border-radius: 30px;
  cursor: pointer;
  border: none; 
  color: #ffffff;
  width:fit-content ;
  font-size: small;
  margin-right: 20px;
  

}

.not-verified {
  background-color: #ff0000;
  border-radius: 30px;
  cursor: pointer;
  border: none; 
  color: #ddd;
  width:fit-content ;
  font-size: small;
  margin-right: 20px;
  
}


.poster-close {
  display: flex; /* Ensure flex display to center the image */
  position: fixed;
  z-index: 9999; /* Set a high z-index so it appears above everything */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
  justify-content: center;
  align-items: center;
}


.poster-full {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.poster-close-modal {
  position: absolute;
  top: 20px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}
