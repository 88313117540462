.service-provider-registration {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-top: 85px;
}

.service-provider-registration h1 {
  color: #333;
  text-align: center;
}

.service-provider-registration form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input[type="email"],
input[type="password"],
input[type="text"],
select,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #004494;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.register-button {
  margin-top: 20px;
}

.selected-items {
  padding: 10px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 4px;
}

.selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #e9e9e9;
  border-radius: 4px;
  margin-top: 8px;
}

.selected-item button {
  background: none;
  border: none;
  color: #900;
  cursor: pointer;
}

.small {
  font-size: 0.85em;
  color: #666;
}

.service-provider-registration div {
  margin-top: 5px;
}

.otp-verified {
  display: none;
}

.show-password {
  display: flex;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-left: 30px;
}

.email-verification-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ff8888;
  padding: 50px;  
  border-radius: 10px;  
}

.v-email {
  margin-top: 0px;
  margin-bottom: 10px;
}

.send-otp {
  width:fit-content;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.verify-otp {
  width:fit-content;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}