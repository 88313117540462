.search-barC {
    width: 20px;
    padding: 8px;
    border-radius: 50px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    
}

.company-search-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
}

.company-search-results li {
    padding: 8px;
    cursor: pointer;
    /* border-bottom: 1px solid #ddd; */
}

.suggestions-list li:hover {
    background-color: #f0f0f0;
}

.company-search-input{
    margin-bottom: 30px;
}
