/* General Styles */
body {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
}

.ab-about-container {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* Header Section */
.ab-about-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d32f2f;
    color: #fff;
    padding: 30px;
    text-align: center;
    height:500px;
    
}

.ab-about-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.ab-about-header h2 {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 20px;
}

.ab-about-header p {
    font-size: 1rem;
    line-height: 1.6;
}

/* Mission Section */
.ab-mission {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.ab-mission h3 {
    font-size: 1.8rem;
    color: #000000;
    margin-bottom: 10px;
}

.ab-mission p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
}

/* Our Story Section */
.ab-our-story {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cecece;
    color: #fff;
    padding: 30px;
    text-align: center;
    height:fit-content;
}

.ab-our-story h3 {
    font-size: 1.8rem;
    color: #a90000;
    margin-bottom: 20px;
}

.ab-our-story p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
}

/* Services Overview */
.ab-services-overview {
    background-color: #fff;
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.ab-services-overview p {
    font-size: 1.2rem;
    color: #555;
    max-width: 850px;
    margin: 0 auto 20px;
    line-height: 1.6;
}

/* List in "What We Do" Section */
.ab-services-overview ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin: 0 auto;
    max-width: 850px;
}

.ab-services-overview ul li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 1.7;
    display: block;
    padding-left: 20px;
    position: relative;
}

.ab-services-overview ul li::before {
    content: "•";
    color: #d32f2f;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 0;
}


/* Why Choose Us Section */
.ab-why-choose-us {
    background-color: #fff;
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.ab-why-choose-us p {
    font-size: 1.2rem;
    color: #555;
    max-width: 850px;
    margin: 0 auto 20px;
    line-height: 1.6;
}

/* List in "Why Choose Us?" Section */
.ab-why-choose-us ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin: 0 auto;
    max-width: 850px;
}

.ab-why-choose-us ul li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 1.7;
    display: block;
    padding-left: 20px;
    position: relative;
}

.ab-why-choose-us ul li::before {
    content: "•";
    color: #d32f2f;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 0;
}


/* Call to Action Section */
.ab-call-to-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d32f2f;
    color: #fff;
    padding: 30px;
    text-align: center;
    height:500px;
}

.ab-call-to-action h3 {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.ab-cta-button {
    background-color: #fff;
    color: #d32f2f;
    padding: 10px 20px;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ab-cta-button:hover {
    background-color: #b71c1c;
    color: #fff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .ab-about-header h1 {
        font-size: 2rem;
    }

    .ab-about-header h2 {
        font-size: 1.1rem;
    }

    .ab-mission, .ab-our-story, .ab-services-overview, .ab-why-choose-us {
        padding: 15px;
    }

    .ab-call-to-action .ab-cta-button {
        width: 100%;
        padding: 10px 0;
    }
}
