/* VerificationDetails.css */

.verification-details {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .verification-details h1 {
    font-size: 2rem;
    color: #d60000;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .verification-details p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
  }
  
  .verification-details ul {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .verification-details ul li {
    font-size: 1rem;
    margin-bottom: 10px;
    list-style-type: disc;
  }
  
  .verification-details h3 {
    font-size: 1.4rem;
    color: #333;
    margin-top: 30px;
    margin-bottom: 10px;
    text-decoration: underline;
  }
  
  .verification-details strong {
    font-weight: bold;
  }
  
  .verification-details button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    background-color: #d60000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px;
    transition: background-color 0.3s ease;
  }
  
  .verification-details button:hover {
    background-color: #c50000;
  }
  
  .verification-details .terms {
    font-size: 0.9rem;
    color: #777;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .verification-details {
      padding: 15px;
      margin: 20px;
    }
  
    .verification-details h1 {
      font-size: 1.8rem;
    }
  
    .verification-details button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }
  