.advertise-service-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    margin-top: 80px;
    
}

.adv-service-img {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adv-service-img img {
    width: 40%;
}

.adv-service-img h2 {
    color: rgb(86, 86, 86);
    font-size:30px;
    font-weight: bold;
    margin-top: 20px;
    
}

.adv-service-img p {
    color: #b71c1c;
    font-size: 20px;
    line-height: 1.6;
    text-align: left;
    max-width: 50%;
} 


.adv-service-img ol {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
    text-align: left;
}



.adservicebutton {
    background-color: #d32f2f;
    border: none;
    width:fit-content;
    border-radius: 5px;
    color: #f9f9f9;
    
}


/* Mobile Styles */
@media (max-width: 768px) {
    .adv-service-img img {
        width: 90%;
    }

    .adv-service-img p {        
        font-size: 18px;
        line-height: 1.6;
        max-width: 90%;
    } 

    .adv-service-img ol {
        font-size: 18px;
        max-width: 90%;
    }

    .adv-service-img h2 {
        font-size:24px;        
    }
    

}
