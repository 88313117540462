/* General Styles */
.our-services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 40px 20px;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto;
}

h1, h2, h3, h4 {
    margin-bottom: 25px;
    font-weight: bold;
    text-align: center;
}

p, li {
    font-size: 1.2rem;
    
}

.image-connect img {
    width: 50%;
}

/* Scoped Styles for List Items in Specific Sections */
.our-services-container .what-we-offer ul li::before,
.our-services-container .how-it-works ul li::before,
.our-services-container .why-choose-us ul li::before,
.our-services-container .industries-we-serve ul li::before {
    content: "•";
    color: #d32f2f;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: -5px;
}


.our-services-container ul {
    list-style-type: none;
    padding-left: 25px;
    position: relative;
}

.our-services-container li {
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
}

/* Header Section */
.header-section {
    padding: 60px 40px;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-bottom: 2px solid #d32f2f;
}

.header-section h1 {
    font-size: 2.8rem;
    color: #d32f2f;
}

.header-section h2 {
    font-size: 1.4rem;
    color: #555;
    margin-top: 10px;
    max-width: 700px;
}

.header-section .cta-button {
    background-color: #d32f2f;
    color: #fff;
    padding: 15px 50px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.cta-button:hover {
    background-color: #b71c1c;
}

.cta-button {
    width: fit-content;
}

/* What We Offer Section */
.what-we-offer {
    background-color: #fff;
    padding: 50px;
    margin-bottom: 50px;
}

.what-we-offer h3 {
    font-size: 2.2rem;
    color: #d32f2f;    
}

.what-we-offer h4 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
}

/* How It Works Section */
.how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #d32f2f;
    margin-bottom: 50px;
    width: 100%;
}

.how-it-works h3 {
    font-size: 2.2rem;
    color: #ffffff;
    margin-bottom: 25px;
}

.step {
    margin-bottom: 30px;
}

.step h4 {
    font-size: 1.5rem;
    color: #ffffff;
    text-align:center;
}

.step p {
    font-size: 1.2rem;
    color: #ebebeb;
    text-align:center;
    width: 500px;
}

/* Why Choose Us Section */
.why-choose-us {
    padding: 50px;
    background-color: #fff;
    margin-bottom: 50px;
}

.why-choose-us h3 {
    font-size: 2.2rem;
    color: #d32f2f;
    margin-bottom: 25px;
}

.why-choose-us ul {
    padding-left: 30px;
}

.why-choose-us ul li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

.why-choose-us ul li strong {
    color: #333;
    font-weight: bold;
}

/* Industries We Serve Section */
.industries-we-serve {
    padding: 50px;
    margin-bottom: 50px;
}

.industries-we-serve h3 {
    font-size: 2.2rem;
    color: #d32f2f;
    margin-bottom: 25px;
}

.industries-we-serve ul li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 15px;
}

.industries-we-serve ul li strong {
    color: #333;
    font-weight: bold;
}

/* Register as Service Provider Section */
.register-service-provider {
    padding: 60px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 60px;
}

.register-service-provider h3 {
    font-size: 2.2rem;
    color: #d32f2f;
    margin-bottom: 25px;
}

.register-service-provider p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
}

/* CTA Section */
.cta {
    padding: 50px;
    background-color: #d32f2f;
    color: #fff;
    text-align: center;
    border-radius: 10px;
}

.cta h3 {
    font-size: 2.2rem;
    margin-bottom: 25px;
}

.cta .cta-button {
    background-color: #fff;
    color: #d32f2f;
    padding: 15px 50px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px;
}

.cta .cta-button:hover {
    background-color: #f0f0f0;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .our-services-container {
        padding: 20px;
    }

    .cta-button {
        width: 100%;
        padding: 15px 0;
    }

    .header-section h1 {
        font-size: 2rem;
    }

    .header-section h2 {
        font-size: 1.2rem;
    }

    .what-we-offer, .how-it-works, .why-choose-us, .industries-we-serve, .register-service-provider, .cta {
        padding: 30px;
        height: fit-content;
    }

    .what-we-offer h3, .how-it-works h3, .why-choose-us h3, .industries-we-serve h3, .register-service-provider h3 {
        font-size: 1.5rem;
    }

    .what-we-offer ul, .how-it-works .step, .why-choose-us ul, .industries-we-serve ul {
        font-size: 1rem;
    }

    .image-connect img {
        width: 90%;
    }

    .step p {
        text-align:center;
        width: 90%;
    }
}
