.splist-provider-list-container {
  padding: 20px;
  text-align: center;
  margin-top: 80px;
}

.splist-provider-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.splist-provider-item {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px; /* Rounded corners for business card style */
  width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, background-color 0.2s; /* Smooth transition */
  cursor: pointer;
}

.splist-provider-item:hover {
  background-color: #e0e0e0; /* Change color on hover */
  transform: scale(1.05); /* Slightly enlarge the item */
}

.splist-provider-item h3 {
  margin: 0 0 10px;
  font-size: 1.5em; /* Slightly larger font for the company name */
}

.splist-provider-item p {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.splist-icon {
  margin-right: 8px;
  color: #007bff; /* Icon color */
}

.splist-advertise-link {
  margin-top: 30px;  
}

/* .splist-advertise-link .btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 50px;
} */

.btn-primary1 {
  background-color: #007bff;
  width:fit-content;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
}

.btn-primary1:hover {
  background-color: #0056b3;
}

.p-verified-status {
  color: #03a000;
}

.p-not-verified-status {
  color: #a00000;
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-container button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
  width:fit-content;
}

.pagination-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-container button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-container span {
  font-size: 1.2em;
  margin: 0 10px;
}

