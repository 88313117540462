.verification-body {
    display: flex;
    align-items: center;
    margin-top: 300px;
    justify-content: center;
    margin-bottom: 50px;

}


.apply-verification-container {     
    width: 80%;
    background-color: azure;   
    padding: 20px;
    border-radius: 10px;
    margin-top: 85px;
}