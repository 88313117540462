/* ServiceProviderProfile.css */

.profile-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 85px;
  }
  
.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
}
  
.profile-pic {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}
  
.profile-pic img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}
  
.default-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #ff6b6b;
    color: white;
    font-size: 48px;
    line-height: 150px;
    text-align: center;
}
  
  /* .file1 {
    display: none;
  }
   */

.company-info {
    text-align: center;
}
  
  .company-info h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
.description-container {
    display: inline-block;
    margin-top: 10px; 
    width:fit-content;
    position: relative;
    white-space: pre-wrap; /* Allows text wrapping */
    line-height: 1.5; /* Adds line height for better readability */
    font-size: 1em; /* Consistent font size */
    word-break: break-word; /* Break words when they are too long */
}

.line {
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin: 20px 0;
}
  
.services-areas {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
  
.section-block {
    width: 48%;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
  
.section-block h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff6b6b;
}
  
.section-block p {
    font-size: 16px;
    color: #333;
}
  
.contact-details {
    background-color: #ffecec;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}
  
.contact-details h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #ff6b6b;
}
  
.contact-details p {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}
  
.post-section {
    margin-bottom: 20px;
    text-align: center;
}
  
.post-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #ff6b6b;
}
  

@media (max-width: 768px) {
    .services-areas {
      flex-direction: column;
    }
  
    .section-block {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .profile-pic img, .default-logo {
      width: 150px;
      height: 150px;
      line-height: 100px;
      font-size: 36px;
    }
  
    .company-info h1 {
      font-size: 20px;
    }
  
    .description-container {
      margin-top: 5px;
    }
  
    .edit-btn1, .find-ads-btn, .upload-btn {
      padding: 8px;
    }
  
    .contact-details {
      padding: 15px;
    }
  
    .contact-details h3 {
      font-size: 18px;
    }
  
    .post-section h3 {
      font-size: 18px;
    }
  
    .line {
      margin: 10px 0;
    }
  }
  
  
  .post-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }
  
  .post-images1 {
    position: relative;
    margin-bottom: 20px;
  
  }
  
  .post-images1 img {
    width: 350px;
    height: 487px;
    border-radius: 5px;
    /* object-fit: cover; */
    border: 2px solid #ddd;
    cursor: pointer;
  }
  
  .default-poster1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 487px;
    border-radius: 5px;  
    background-color: #ffffff;
    color: rgb(152, 152, 152);
    font-size: 26px;
    /* line-height: 30px; */
    text-align: center;
    border: 1px solid #ddd;
    
        
}

.loading {
    background-color: aquamarine;
    margin-top: 85px;
    height: 1000px;
    text-align: center;    
}

.modal1 {
    display: flex; /* Ensure flex display to center the image */
    position: fixed;
    z-index: 9999; /* Set a high z-index so it appears above everything */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
    justify-content: center;
    align-items: center;
}


.modal-content1 {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.close1 {
    position: absolute;
    top: 20px;
    right: 35px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.company-name-status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adds space between the company name and the verification status */
}

.company-name-status h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.verified-status {
    color: green;
    font-size: 18px;
}

.not-verified-status {
    color: red;
    font-size: 18px;
    background-color: #ffdddd;
    padding: 0 10px;
    border-radius: 4px;
}


  

 
