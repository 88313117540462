.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #ffffff;
  position: fixed; /* This makes the header bar static */
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensures the header is above other content */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.main-container1 {
  margin-top: 90px; /* Adjust to prevent content from being hidden behind the header */
}

.logo a {
  text-decoration: none;
}

.logo h1 {
  color: #a90000;
  font-size: 24px;
  margin: 0;
}

/* .logo h1 {
  color: white;
  font-size: 24px;
  margin: 0;
} */

.menu-icon {
  position: relative;
}

.menu-button-container {
  cursor: pointer;
  width: 24px;
  height: 18px;
  position: relative;
  z-index: 2;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  background-color: #a90000;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  position: absolute;
  display: block;
  content: '';
  transition: all 0.3s ease-in-out;
}

.menu-button::before {
  top: -8px;
}

.menu-button::after {
  top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button {
  transform: rotate(45deg);
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  transform: rotate(90deg);
  top: 0;
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  transform: rotate(90deg);
  top: 0;
}

.menu {
  position: absolute;
  top: 40px;
  right: 0;
  width: 160px;
  background-color: #D22B2B;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
}

.menu li {
  list-style: none;
  padding: 10px;
}

.menu li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

#menu-toggle:checked ~ .menu {
  display: flex;
}

#menu-toggle {
  display: none;
}

/* footer */


.footer-bar {
  background-color: #8B0000; /* dark red color */
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-bar .footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-bar .footer-section {
  flex: 1;
  margin: 10px;
  min-width: 250px;
}

.footer-bar .footer-section h3 {
  margin-bottom: 15px;
}

.footer-bar .footer-section a {
  color: white;
  text-decoration: none;
}

.footer-bar .footer-section a:hover {
  text-decoration: underline;
}

.footer-bar .footer-section p {
  margin: 5px 0;
}

.footer-bar .social-icons a {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}

.footer-bar .social-icons a:hover {
  color: #FFD700; /* gold color for hover */
}

/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .footer-bar .footer-content {
      flex-direction: column;
      text-align: center;
  }

  .footer-bar .footer-section {
      margin: 10px 0;
  }
}


