.provider-info-container {
    max-width: 800px;
    margin: 50px auto;
    margin-top: 120px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #c00;
  }
  
  .intro-section, .benefits-section, .how-it-works-section, .why-choose-us-section, .get-started-section {
    margin-bottom: 20px;
  }
  
  .benefits-section ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .benefits-section li {
    background-color: #f4f4f4;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .register-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #c00;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .register-button:hover {
    background-color: #900;
  }
  