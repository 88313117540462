.track-advertisement-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
}

.track-advertisement-container h2 {
    text-align: center;
    color: #b00000;
    margin-bottom: 20px;
    font-size: 24px;
}

.track-advertisement-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.track-advertisement-container label {
    width: 100%;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
}

.track-advertisement-container input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.track-advertisement-container button {
    width: 50%;
    padding: 10px;
    background-color: #b00000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.track-advertisement-container button:hover {
    background-color: #a30000;
}

.advertisement-details {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.advertisement-details p {
    margin: 0 0 10px;
    font-size: 16px;
    color: #ff0101;
}
