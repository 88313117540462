/* MainPage.css */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

header {
  /* background-color: #d32f2f; */
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.burger-menu {
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
}

.nav-menu .menu-item {
  display: block;
  padding: 10px 20px;
  color: #d32f2f;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
}

.nav-menu .menu-item:hover {
  background-color: #f7f7f7;
}

.find-provider-button {
  background-color: #d32f2f;
  color: white;
  /* padding: 10px; */
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 30%;
  margin-top: 20px;
}

.find-provider-button:hover {
  background-color: #b71c1c;
}

.register-link1 a {
  color: #d32f2f;
  text-decoration: none;
  font-size: 16px;
  padding-top: 20px;
}

.register-link1 a:hover {
  text-decoration: underline;
}

.register-link1 {
  text-align: left;
}

.dropdown-container select {
  padding: 10px;
  font-size: 16px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.dropdown-container {
  margin-bottom: 15px;
  margin-top: 20px;
}

.main-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  /* border-radius: 8px; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 85px;
}

.image-container {
  margin-top: 60px;
}

.image-container img {
  width: 100%;
  /* border-radius: 8px; */
}

.business-description {
  /* background-color: #ffffff; */
  padding: 20px;
  /* border-radius: 10px; */
  margin-top: 50px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.business-description h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  /* margin-bottom: 10px; */
  text-align: left;
}

.business-description h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: left;
}

.business-description p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 0;
}

.service-finder-container h1 {
  font-size: 30px;
  font-weight: bold;
  color: #d32f2f;
  /* margin-bottom: 10px; */
  text-align: left;
}
.service-finder-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #d32f2f;
  /* margin-bottom: 10px; */
  text-align: left;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure it overlays other content */
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%; /* Adjust width as needed */
  max-width: 600px; /* Max width for larger screens */
  min-width: 300px; /* Min width for smaller screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  position: relative; /* Allows positioning of close button */
}

/* Close Button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.ad-yourself {

  background-color: rgb(9, 111, 158);
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  height: 60px;
  color: #ddd;
  font-size:larger;
  height: fit-content;

}

.caution-note {
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  margin-bottom: 10px;  
}
